<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 464px">
      <div class="layer_head">
        <strong class="tit_popup">서비스 검색</strong>
      </div>
      <div class="layer_body">
        <!-- 필터바 -->
        <div class="box_sorting">
          <strong class="screen_out">서비스 검색하기</strong>
          <ul class="list_sorting">
            <li class="item_full">
              <!-- <em class="tit_txt">검색어</em> -->
              <div class="row_pack">
                <Input
                  style="width: 240px"
                  placeholder="검색어를 입력하세요"
                  :value.sync="searchForm.searchKeyword"
                />
                <button
                  type="button"
                  class="btn_medium btn_primary"
                  @click.prevent="clickSearchButton"
                >
                  검색
                </button>
              </div>
            </li>
          </ul>
        </div>
        <!-- 법인카드 사용내역 리스트 -->
        <div class="tbl_comm tbl_list tbl_list_sm8 tbl_scroll">
          <div
            class="inner_scroll"
            style="height: calc(100vh - 544px); max-height: 294px; min-height: 49px"
          >
            <table>
              <colgroup>
                <col style="width: 52px" />
                <!-- 선택 -->
                <col />
                <!-- 사용금액 -->
              </colgroup>
              <thead>
                <tr>
                  <th style="width: 52px" />
                  <th style="width: 348px">서비스</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="serviceDataList.length === 0">
                  <td colspan="9">조회된 서비스코드가 없습니다</td>
                </tr>
                <tr
                  v-for="(item, index) in serviceDataList"
                  :key="index"
                  :class="{ tr_select: isSelected(item) }"
                >
                  <td>
                    <div class="box_choice type_alone">
                      <div class="item_choice">
                        <!-- disabled 상태시 disabled 클래스 추가 -->
                        <input
                          :id="item.serviceCode"
                          v-model="selectedService"
                          type="radio"
                          class="inp_choice"
                          name="selectedCorporateCard"
                          :value="item"
                        />
                        <label class="lab_choice" :for="item.serviceCode">
                          <span class="ico_account ico_rdo" />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td class="td_ellip">
                    {{ item.serviceName }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            취소
          </button>
          <button
            type="button"
            class="btn_medium btn_secondary"
            :disabled="isDisabledMoveBtn"
            @click.prevent="onClickSelected"
          >
            선택
          </button>
          <!-- <button v-if="isBillSeleted" type="button" class="btn_medium btn_primary" :disabled="isDisabledSeletedBtn" @click.prevent="onClickSelected">선택</button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Input from "@/_approval/components/common/input/Input";
import ApiService from "@/services/ApiService";

export default {
  name: "PopService",
  components: {
    Input,
  },
  data() {
    return {
      searchForm: {
        searchKeyword: "",
      },
      serviceDataList: [
        // api 연동 전 임시 서비스 데이터
        //   { serviceCode:'00001', projectName: '서비스명1' },
        //   { serviceCode:'00002', projectName: '서비스명2' },
        //   { serviceCode:'00003', projectName: '서비스명3' },
        //   { serviceCode:'00004', projectName: '서비스명4' },
        //   { serviceCode:'00005', projectName: '서비스명5' },
        //   { serviceCode:'00006', projectName: '서비스명6' },
        //   { serviceCode:'00007', projectName: '서비스명7' },
        //   { serviceCode:'00008', projectName: '서비스명8' },
        //   { serviceCode:'00009', projectName: '서비스명9' },
      ],
      selectedService: {}, // 선택한 서비스 데이터
    };
  },
  computed: {
    isDisabledMoveBtn() {
      return !this.selectedService.serviceCode;
    },
  },
  mounted() {
    this.getProjectCode();
  },
  methods: {
    isSelected(item) {
      return this.selectedService == item;
    },
    clickSearchButton() {
      // const obj = {};
      // if(this.searchForm.searchKeyword) obj.deptName = this.searchForm.searchKeyword;

      this.getProjectCode();
    },
    onClickSelected() {
      this.$emit("onClickComplete", this.selectedService);
    },
    async getProjectCode() {
      // if(this.searchForm.searchKeyword == '') {
      //    this._showLayer({type: 'alert', content: '검색어를 입력해주세요'});
      //    return;
      // }

      // const path = `${ this.$apiPath.VOUCHER_ORG_SERVICE }?serviceNm=${ this.searchForm.searchKeyword }`;
      let path = `${this.$apiPath.PROJECT_CODE}`;
      if (this.searchForm.searchKeyword != "") {
        path += `?projectName=${this.searchForm.searchKeyword}`;
      }

      const res = await ApiService.shared.getData(path);

      if (res.data) {
        this.serviceDataList = res.data.map((item) => {
          const obj = {};
          obj.serviceCode = item.projectCode;
          obj.serviceName = item.projectName;
          return obj;
        });
      } else {
        this.serviceDataList = [];
      }
    },
  },
};
</script>
